@import 'src/theme/constants';

.search {
    &__left-buttons {
        margin-right: $jmt-spacing * 2;
        align-self: center;
    }

    &__input {
        max-width: 400px;
    }
}
