@import 'src/theme/constants';

.playlist-card-small {
    width: 100%;
    background-color: $jmt-color-stripe-dark;

    &__image-wrapper {
        position: relative;
    }

    &__ctx-menu {
        position: absolute;
        top: 0;
        right: 0;
    }
}
