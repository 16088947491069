@import 'src/theme/constants';

.select-with-order {
    &__add-value .popover-content {
        width: 90vw;
        max-width: $jmt-max-content-width;
    }

    &__label-wrapper {
        display: flex !important;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__sortable-card {
        margin: $jmt-spacing;

        .ion-text-center {
            ion-label {
                justify-content: center;
            }
        }

        ion-icon {
            margin-right: $jmt-spacing;
        }
    }

    &__add-btn {
        --color: var(--ion-color-step-650);
    }

}
