@import "src/theme/constants";

.talent-edit-form {
    &__submit-position-helper {
        display: flex;
        margin: $jmt-spacing * 4 0;
    }

    &__submit-btn {
        margin: 0 auto;
    }
}
