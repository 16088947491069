@import 'src/theme/constants';
@import 'src/theme/mixins';

.talent-performance-vote {


    &__value {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__slider-row {
        position: relative;
        margin-top: -1 * $jmt-spacing;

        &--compare {
            margin-top: -3 * $jmt-spacing;
        }
    }

    &__card {
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-rgba, 20%);
    }

    &__form {
        padding: $jmt-spacing * 2;
        margin: 0 $jmt-spacing;

        ion-label {
            z-index: 1;
            display: inline-flex;
            align-items: center;
            height: 100%;

            ion-icon {
                padding-left: calc(#{$jmt-spacing} / 2);
            }
        }

        ion-range {
            --bar-background: #{$jmt-color-stripe-light};
            --knob-size: 0;
            opacity: 1;
            margin-right: $jmt-spacing * 5;
            padding: 0;

            .range-knob-handle {
                display: none;
            }
        }

    }

    .swiper-slide .skills-list-view__card {
        width: calc(100% - #{$jmt-spacing * 2});
    }
}
