@import 'src/theme/constants';
@import 'src/theme/mixins';
@import 'src/theme/transitions';

.talent-timeline {
  margin-bottom: $jmt-spacing*4;
  position: relative;

  &__headline {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }

  &__line {
    min-height: 100px;
    position: absolute;
    width: 2px;
    height: 100%;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0, rgba(255,255,255,.4) 32px, rgba(255,255,255,.4) calc(100% - 32px), rgba(255,255,255,0) 100%);

    @include desktop {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__divider {
    margin-top: $jmt-spacing;
    margin-left: $jmt-spacing;
    margin-bottom: -$jmt-spacing;
    @include desktop {
      margin-left: 0;
    }
  }

  &__entry-wrapper {
    position: relative;
    margin-top: $jmt-spacing*2;
    margin-left: $jmt-spacing*2;

    @include desktop {
      display: flex;
      &:nth-of-type(even) {
        margin-left: 0;
        margin-right: $jmt-spacing*2;

        .talent-timeline__entry {
          text-align: right !important;
          .talent-timeline__entry__caption {
            text-align: right !important;
          }
          ion-row {
            justify-content: flex-end;
          }
          ion-card-content, ion-card-subtitle, ion-card-title {
            text-align: right !important;
          }
          .talent-timeline__certificate {
            flex-direction: row-reverse;
          }
          &:after {
            left: calc(50% + 2px);
          }


        }

        .talentcard-list-item__talent-id {
          left: -1.4 * $jmt-spacing !important;
          right: unset !important;
        }
      }

      &:nth-of-type(odd) {
        justify-content: end;
        margin-left: $jmt-spacing*2;
        margin-right: 0;

        .talent-timeline__entry {
          &:after {
            left: calc(50% - 14px);
          }
        }
      }
    }
  }

  &__entry {

    @include desktop {
      width: calc(50% - #{$jmt-spacing*2});
    }
    ion-card {
      @include complexTransition(all);
    }

    ion-card-content {
      width: 100%;
      max-width: 100%;
    }

    &__caption {
      white-space: nowrap;
      max-width: calc(100% - 70px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include desktop {
      ion-card-content {
        width: 340px;
      }
      &__caption {
        max-width: 100%;
      }
    }




    &--edit ion-card {
      cursor: pointer;
      border-radius: $jmt-border-radius;
      box-shadow: 0 0 8px var(--ion-color-primary);
      animation: wiggle 1.75s infinite ease-in-out;
    }

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -21px;
    }
  }

  &__certificate {
    cursor: pointer;
    display: flex;
    color: white;
    img {
      max-height: 66px;
      width: auto;
    }
  }

  @keyframes wiggle {
    0% { transform: rotate(0deg); }
    20% { transform: rotate(0deg); }
    25% { transform: rotate(1deg); }
    30% { transform: rotate(-1deg); }
    35% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }
}