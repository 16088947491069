@import "src/theme/transitions";

.collapsible {
    height: 0;
    @include screenLeaveTransition(height, overflow);
    line-height: unset;
    overflow: hidden;

    &--open {
        @include screenEnterTransition(height, overflow);
    }
}
