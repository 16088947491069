@import 'src/theme/constants';

.tab-bar {
    display: flex;
    bottom: -1px; // needed due to ionic :(
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: calc(#{$jmt-tabbar-height} + env(safe-area-inset-bottom));
    background: var(--ion-card-background);
    border-radius: $jmt-spacing * 3 $jmt-spacing * 3 0 0;
    margin-top: $jmt-spacing * -1;
    padding-bottom: calc(#{$jmt-spacing} +  env(safe-area-inset-bottom));
    overflow: hidden;
    max-width: $jmt-max-tabbar-width;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $jmt-min-width-tablet) {
        border-top: 1px solid var(--ion-background-color);
        border-left: 1px solid var(--ion-background-color);
        border-right: 1px solid var(--ion-background-color);
    }

    &__search {
        position: relative;
        display: flex;
        flex: 1 1;
        max-width: 64px;

        .tab-btn__btn--active {
            --ion-color-base: var(--ion-color-secondary-tint)!important;
            filter: drop-shadow(0 0 3px #{var(--ion-color-secondary-shade)});
        }
    }

    &--hidden {
        display: none;
    }
}
