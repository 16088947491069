@import "constants";

@mixin background-gradient($background-color, $gradient-color, $offset) {
    background-color: $background-color;
    background-image:
            linear-gradient(130deg,
                    transparent calc(#{$offset} + 20px),
                    $gradient-color calc(#{$offset} + 20px),
                    $gradient-color calc(#{$offset} + 30px),
                    transparent calc(#{$offset} + 30px)
            ),
            linear-gradient(130deg,
                    transparent calc(#{$offset} + 41px),
                    $gradient-color calc(#{$offset} + 41px),
                    $gradient-color calc(#{$offset} + 49px),
                    transparent calc(#{$offset} + 49px)
            ),
            linear-gradient(130deg,
                    transparent calc(#{$offset} + 61px),
                    $gradient-color calc(#{$offset} + 61px),
                    $gradient-color calc(#{$offset} + 67px),
                    transparent calc(#{$offset} + 67px)
            ),
            linear-gradient(130deg,
                    transparent calc(#{$offset} + 80px),
                    $gradient-color calc(#{$offset} + 80px),
                    $gradient-color calc(#{$offset} + 84px),
                    transparent calc(#{$offset} + 84px)
            ),
            linear-gradient(130deg,
                    transparent calc(#{$offset} + 98px),
                    $gradient-color calc(#{$offset} + 98px),
                    $gradient-color calc(#{$offset} + 100px),
                    transparent calc(#{$offset} + 100px)
            );
}

@mixin desktop {
    @media (min-width: $jmt-width-desktop) { @content; }
}
