ion-content {
    --padding-start: #{$jmt-content-inset};
    --padding-end: #{$jmt-content-inset};
    --padding-top: #{$jmt-content-inset * 2};
    --padding-bottom: #{$jmt-content-inset * 2};

    @include desktop {
        --padding-top: #{$jmt-content-inset * 4}!important;
    }

    > *:not(.content__background) {
        max-width: $jmt-max-content-width;
        margin: 0 auto #{$jmt-content-inset * 2.5} auto!important;

        @include desktop {
            max-width: $jmt-width-desktop - $jmt-spacing * 4;
        }
    }
}
