@import "src/theme/constants";

.form-checkbox {
    display: flex;
    justify-content: space-between;
    padding: $jmt-spacing;

    ion-item {
        flex-grow: 1;
        margin: 0 $jmt-spacing * -1;
        --ion-item-background: transparent;
    }

    &__error-text {
        padding-left: $jmt-spacing;
    }
}

