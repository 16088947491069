@import 'src/theme/constants';

.user-teaser-slider {
    &__content {
        & > * {
            margin-bottom: $jmt-spacing * 1.5;
        }
    }

    &__slide {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__show-more {
        align-self: center;
        margin-top: $jmt-spacing * 1.5;
    }
}
