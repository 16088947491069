@import "/src/theme/constants";

.borussia-shanghai {
    min-width: 992px;
    padding: $jmt-spacing*2;

    .borussia-shanghai-wrapper {
        min-width: 960px;
        height: 640px;
        background-color: #231f20;
        margin-bottom: $jmt-spacing * 4;
        position: relative;
        box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.5);

        &__talent-card-col {
            background-color: #dbd9da;
            max-width: 370px;
            position: relative;
        }

        &__cert-col {
            padding-left: 40px;
            max-width: 590px;
            min-width: 590px;
        }

        &__arrow-right {
            width: 0;
            height: 0;
            border-top: 70px solid transparent;
            border-bottom: 70px solid transparent;
            border-left: 50px solid #dbd9da;
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-72%);
        }

        &__footer {
            height: 96px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            background: #ffffff;
            padding: $jmt-spacing*2;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            align-items: center;

            &__jmt-logo {
                height: 64px;
                min-width: 96px;
                padding-left: 32px;
            }

            &__text {
                margin-left: -16px;
            }

            .borussia-shanghai__logo {
                margin-left: -24px;
                svg {
                    height: 90px;
                    margin-right: -16px;
                }
                &__text {
                    scale: .8;
                }
            }
        }
    }

    color: #ffffff;
    --color: #ffffff;
    --ion-text-color: #ffffff;
    background-color: #ffffff;
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
    --ion-font-family: BorussiaShanghai, serif;
    font-family: BorussiaShanghai, serif;

    --ion-color-primary: #7ab929;
    --ion-color-primary-rgb: 122, 185, 41;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #62961e;
    --ion-color-primary-tint: #89ce31;

    /** secondary **/
    --ion-color-secondary: #000000;
    --ion-color-secondary-rgb: 0, 0, 0;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0f0f0f;
    --ion-color-secondary-tint: #373737;

    h1, h2, h3 {
        font-family: BorussiaShanghaiSpecial, serif;
    }

    .talentcard-medium {
        scale: .8;
        --ion-color-secondary: #7ab929;
        --ion-color-scout: #7ab929;
        color: white;

        .performance-icon {
            display: none;
        }

        .talentcard-medium-front__ctx-menu {
            display: none;
        }
    }

    &__headline {
        font-size: 54px;
    }

    &__logo {
        scale: .8;
        display: flex;
        svg {
            max-height: 90px;
            margin-right: $jmt-spacing;
        }

        h1 {
            font-size: 36px;
            line-height: 36px;
            margin: 0;

            &:first-of-type {
                color: var(--ion-color-primary);
                margin-bottom: -6px;
            }
        }

        h2 {
            color: #8c8c8c;
        }

        &__text {
            margin-top: -8px;
            transform: rotate(-5deg);
        }
    }

    &__text {
        color: #ffffff;
        margin-top: $jmt-spacing *3;
        font-size: 26px;

        p {
            font-size: 18px;
            padding-bottom: $jmt-spacing*4;
        }

        &__name {
            font-weight: 700;
        }
    }

    &__brush {
        font-family: BorussiaShanghaiBrush, serif;
        color: var(--ion-color-primary);
        font-size: 44px;
        line-height: 40px;
        font-weight: 700;
        text-align: center;
        transform: rotate(-20deg);
    }

    &__group-headline {
        margin-left: $jmt-spacing;
        margin-bottom: $jmt-spacing;
    }

    .ion-padding {
        padding-left: $jmt-spacing * 3;
        padding-right: $jmt-spacing * 3;
    }

    &__skills {
        margin-bottom: $jmt-spacing * 6;
    }

    .skills-list-view__card ion-icon {
        display: none;
    }
}

@font-face {
    font-family: BorussiaShanghai;
    src: url( './../../../assets/fonts/signikanegative-bold.woff');
    font-weight: 300;
}

@font-face {
    font-family: BorussiaShanghai;
    src: url( './../../../assets/fonts/signikanegative-regular.woff');
    font-weight: 500;
}

@font-face {
    font-family: BorussiaShanghaiSpecial;
    src: url( './../../../assets/fonts/bmg-bungee.woff');
}

@font-face {
    font-family: BorussiaShanghaiBrush;
    src: url( './../../../assets/fonts/bmg-goodbrush.woff');
}
