@import "src/theme/constants";

.comparison-talent {
    display: flex;
    padding: $jmt-spacing * 3;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: calc(50% - #{$jmt-spacing * 0.5});

    &__delete-btn {
        position: absolute;
        top: 0;
        left: 0;
    }

    &--secondary {
        background: linear-gradient(45deg, #{var(--ion-color-secondary)}, $jmt-color-orange);
    }

    &--tertiary {
        background-color: var(--ion-color-tertiary);
    }

    &__background {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        filter: invert(1) opacity(0.05);
        object-fit: cover;
    }

    &__img-container {
        position: relative;
        margin-bottom: $jmt-spacing * 3;
    }

    &__img {
        position: relative;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.05);
        flex-shrink: 0;
        width: $jmt-spacing * 10;
        height: $jmt-spacing * 10;
        display: block;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.05);
        margin: 0 auto;
    }

    &__dots1 {
        z-index: 0;
        position: absolute;
        left: 50%;
        transform: translateX($jmt-spacing * -8);
        bottom: -1 * $jmt-spacing;
        max-width: none !important;
        width: $jmt-spacing * 5;
        height: $jmt-spacing * 5;
    }

    &__dots2 {
        z-index: 0;
        position: absolute;
        left: 50%;
        transform: translateX($jmt-spacing * 3);
        top: -1 * $jmt-spacing;
        max-width: none !important;
        width: $jmt-spacing * 5;
        height: $jmt-spacing * 5;
    }

    &__border {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: none !important;
        width: $jmt-spacing * 12;
        height: $jmt-spacing * 12;
        transform: translate(-50%, -50%);
    }
}
