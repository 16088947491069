@import 'src/theme/constants';
@import 'src/theme/mixins';

.teaser-card {
    position: relative;
    height: $jmt-teaser-card-height;
    background-position: center;
    background-size: cover;
    background-color: white;

    ion-img {
        object-fit: cover;
        object-position: center right;
        position: absolute;
        right: 0;
        bottom: 0;
        left: auto;
        top: 0;
        max-height: 100%;
    }

    &__row {
        height: 100%;
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        //background: linear-gradient(to right, rgba(0, 0, 0, 0.85) 25%, transparent 80%);
    }

    &__content {
        max-width: 65%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        justify-content: flex-end;
        padding: 5%;
        @include desktop {
            max-width: 50%;
        }

        h2 {
            margin-bottom: $jmt-spacing;
        }

        ion-card-subtitle {
            margin-bottom: $jmt-spacing * 3;
        }
    }

    &--dark {
        h2,
        ion-card-subtitle {
            color: var(--ion-text-color-secondary);
        }

        .teaser-card__overlay {
            background: linear-gradient(to right, rgba(255, 255, 255, 0.85) 60%, transparent 80%);
        }
    }
}
