@import 'src/theme/constants';

.desktop-main-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $jmt-desktop-menu-width;
    z-index: 1;
    background-color: var(--ion-card-background);
}

.menu-item {
    cursor: pointer;
}
