@import 'src/theme/constants';
@import 'src/theme/mixins';

.user-list-item {
    position: relative;
    display: flex;
    margin-bottom: $jmt-spacing * 1.5;
    cursor: pointer;

    &__relationship-icon {
        margin-left: $jmt-spacing * 0.5;
    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &::part(native) {
        display: flex;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ion-card-content {
        width: 100%;
    }

    &__grid {
        padding: 0 $jmt-spacing * 2;
    }

    ion-grid {
        display: flex;
    }

    ion-row {
        width: 100%;
    }

    &__menu {
        flex-grow: 0;
    }
}
