@import "constants";

/// Transitions
///
/// @param {List<string>} $property
///   The number to multiply by itself.
/// @param {'shortest' | 'shorter' | 'short' | 'standard' | 'complex' | 'enteringScreen' | 'leavingScreen'} $timingName
///   name of timing (values of $jmt-timings)
/// @param {'easeInOut'  | 'sharp'  | 'easeOut'  | 'easeIn'} $transitionName
///   name of transition (values of $jmt-transition)
///
@mixin transition($property, $timingName, $transitionName) {
  $transition: ();
  @for $i from 1 through length($property) {
    $transition: append(
            $transition, (nth($property, $i) map-get($jmt-timings, $timingName) map-get($jmt-transitions, $transitionName)),
        $separator: comma
    );
  }
  transition: $transition;
}

@mixin complexTransition($property...) {
  @include transition($property, complex, easeInOut)
}

@mixin screenEnterTransition($property...) {
  @include transition($property, enteringScreen, easeOut)
}

@mixin screenLeaveTransition($property...) {
  @include transition($property, leavingScreen, easeIn)
}

@mixin standardTransition($property...) {
  @include transition($property, standard, easeInOut)
}

@mixin shortTransition($property...) {
  @include transition($property, short, easeInOut)
}

@mixin shorterTransition($property...) {
  @include transition($property, shorter, sharp)
}

@mixin shortestTransition($property...) {
  @include transition($property, shortest, sharp)
}
