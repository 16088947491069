@import 'src/theme/constants';
@import 'src/theme/mixins';

.talent-timeline-item {
  margin-top: $jmt-spacing * 2;

  &__entry {
    position: relative;
    display: flex;
    cursor: pointer;
    margin-left: $jmt-spacing * 2;

    &__actual {
    }

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: white;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
    }

    &__card {
      padding: $jmt-spacing;

      &--team {
        cursor: pointer;
      }
    }
  }


  &__caption-wrapper {
    z-index: 1;
  }

  &::part(native) {
    display: flex;
  }

  &__talent-id {
    position: absolute;
    right: -1.4 * $jmt-spacing;
    width: $jmt-spacing * 12;
    height: $jmt-spacing * 12;
    opacity: 0.4;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ion-card-content {
    width: 100%;
  }

  &__grid {
    padding: 0 $jmt-spacing * 2;
  }

  ion-grid {
    display: flex;
  }

  ion-row {
    width: 100%;
  }

  &__menu {
    flex-grow: 0;
  }
}