@import 'src/theme/constants';

.network-card-small {
    width: 100%;
    max-width: $jmt-max-card-width;
    position: relative;
    padding-bottom: $jmt-spacing*4;

    &__owner {
        position: absolute;
        right: $jmt-spacing;
        bottom: $jmt-spacing;
        margin-top: $jmt-spacing;
        display: flex;
        align-items: center;

        ion-icon {
            margin-right: $jmt-spacing;
        }
    }
}
