@import 'src/theme/constants';
@import 'src/theme/mixins';

.custom-avatar {
    overflow: hidden;
    flex-shrink: 0;

    &--fallback {
        ion-img {
            padding: $jmt-spacing * 2;
            object-fit: contain!important;
        }
    }

    &__position-helper {
        position: relative;
    }

    ion-img, ion-skeleton-text {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }

    &__position-helper ion-icon {
        width: 60%;
        height: 60%;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);

        &.full-icon-height {
            width: 100%;
            height: 100%;
        }

        &.background {
            &--white {
                background-color: white;
            }
        }
    }

    ion-img {
        background: var(--avatar-background, white);
    }

    &--person-reverse {
        .custom-avatar__position-helper {
            padding-bottom: 100%; // 1:1 aspect ratio
        }

        ion-icon, ion-img, ion-skeleton-text {
            object-position: center top;
            clip-path: circle(100% at 100% 50%);
        }
    }

    &--person {
        .custom-avatar__position-helper {
            padding-bottom: 100%; // 1:1 aspect ratio
        }

        ion-icon, ion-img, ion-skeleton-text {
            object-position: center top;
            clip-path: circle(100% at 0% 50%);
        }
    }

    &--network {
        .custom-avatar__position-helper {
            padding-bottom: 75%; // 4:3 aspect ratio
        }

        ion-icon, ion-img, ion-skeleton-text {
            object-position: center center;
            border-radius: 0;
        }
    }

    &--playlist {
        .custom-avatar__position-helper {
            padding-bottom: 75%; // 4:3 aspect ratio
            display: flex;
            justify-content: center;
            align-items: center;
            @include background-gradient(var(--ion-background-color), $jmt-color-stripe-dark, 40%);
        }

        .custom-avatar__playlist-background {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: var(--ion-color-secondary);
            opacity: .5;
        }

        ion-img {
            border-radius: $jmt-border-radius;
            overflow: hidden;
            width: 37%;
            height: 46%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-20%, -20%);
            z-index: 0;
            opacity: 0.8;
            border: 1px solid $jmt-color-light-gold;

        }
        &.custom-avatar--fallback ion-img {
            padding: $jmt-spacing * 0.5!important;
        }

        ion-icon, ion-img, ion-skeleton-text {
            object-position: center top;
        }
    }

    &--round {
        .custom-avatar__position-helper {
            padding-bottom: 100%; // 1:1 aspect ratio
        }

        ion-icon, ion-img, ion-skeleton-text {
            object-position: center top;
            border-radius: 50%;
            border: 1px solid $jmt-color-avatar-background;
        }
    }

    &__delete-btn {
        margin-top: $jmt-spacing;
    }
}
