@import 'src/theme/constants';

.activity-page {
    &__activity-wrapper {
        position: relative;

        > ion-card {
            height: $jmt-spacing * 10;
        }
    }

    &__date {
        width: 100% !important;

        .divider {
            margin-bottom: $jmt-spacing;
        }

        &:not(:first-child) {
            margin-top: $jmt-spacing * 4;
        }
    }

    &__badge {
        position: absolute;
        top: $jmt-spacing;
        right: 0;
        z-index: 1;
        transform: translate(0.5 * $jmt-spacing, -1.5 * $jmt-spacing);

    }

    &__item-wrapper {
        > * {
            margin-bottom: $jmt-spacing * 1.5;
        }
    }
}
