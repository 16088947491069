@import "src/theme/constants";

.invitation-answer {
    &__text-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $jmt-spacing * 2;
        margin-top: $jmt-spacing * 3;
    }

    &__user-card {

        > * {
            max-width: 360px;
            margin: 0 auto;
        }
    }

    &__text {
        padding: 0 $jmt-spacing * 2;

        &:before {
            content: '“';
            font-family: Rubik, sans-serif;
            color: var(--ion-color-step-600);
            font-size: $jmt-fontsize-h2;
            position: absolute;
            top: $jmt-spacing;
            left: $jmt-spacing * 2;
        }

        &:after {
            content: '”';
            font-family: Rubik, sans-serif;
            color: var(--ion-color-step-600);
            font-size: $jmt-fontsize-h2;
            position: absolute;
            bottom: -0.5 * $jmt-spacing;
            right: $jmt-spacing * 2;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
