@import 'src/theme/constants';

.invite-activity {
    display: flex;
    align-items: center;
    padding-right: $jmt-spacing;
    justify-content: space-between;

    &__skeleton {
        margin-right: $jmt-spacing;
        width: $jmt-spacing * 5;
        display: inline-block;
    }

    &__avatar {
        margin-right: $jmt-spacing;
        min-height: 100%;
    }

    &__text {
        flex-grow: 1;
        padding-right: $jmt-spacing * 2;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;

        &--small {
            font-size: 12px;
        }
    }
}
