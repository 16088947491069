@import 'src/theme/constants';
@import 'src/theme/mixins';

.user-card-medium {
    display: flex;
    width: 100%;
    max-width: $jmt-max-card-width;

    &__follow-btn {
        margin: $jmt-spacing 0 0;
    }

    &__trainer-wrapper {
        color: $jmt-color-gold;
        display: flex;
        align-items: center;

        img {
            margin-right: $jmt-spacing * 0.5;
            width: $jmt-spacing * 2;
        }
    }

    &__ctx-menu-wrapper {
        flex-grow: 0;
    }

    &__head {
        flex-grow: 1;
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-light, 50%);
        overflow: hidden;

        &__content {
            padding: 0 $jmt-spacing * 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }

    &--trainer {
        .user-card-medium__head {
            @include background-gradient(var(--ion-color-base), $jmt-color-light-gold, 50%);
        }

        .user-card-content__background--column {
            @include background-gradient(var(--ion-color-base), $jmt-color-light-gold, 5%);
        }
    }

    &__head-col {
        display: flex;
        align-items: center;
    }

    &__club-img {
        width: $jmt-spacing * 5 !important;
        margin-right: $jmt-spacing;
    }

    &__buttons {
        padding-top: $jmt-spacing * 2;
        padding-bottom: $jmt-spacing * 2;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-light, 10%);
    }

    .user-card-content {
        background-color: $jmt-color-stripe-light;
        padding: calc(#{$jmt-spacing} * (1 / 8));
        margin-left: calc(#{$jmt-spacing} * (-1 / 4));
        margin-right: calc(#{$jmt-spacing} * (-1 / 4));

        &__background {
            background-color: $jmt-color-stripe-light;

            ion-col {
                background-color: transparent;
                margin: 0;
            }

            &--column {
                display: flex;
                flex-direction: column;
            }
        }

        ion-col {
            margin: calc(#{$jmt-spacing} * (1 / 8));
            background-color: white;
            display: flex;
            padding: $jmt-spacing;
        }
    }

}
