@import "/src/theme/constants";
@import "/src/theme/transitions";

.rbl-talentcard-medium-back {
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
    @include complexTransition(transform);
    pointer-events: none;
    width: 100%;

    &__profile-link {
        cursor: pointer;
        margin-bottom: $jmt-spacing * 2;

        > * {
            font-size: 18px;
            line-height: 20px;
            text-shadow: 0 0 4px black;
        }
    }

    &--visible {
        transform: rotateY(-360deg);
        pointer-events: all;
    }

    &__switch-btn {
        position: absolute;
        bottom: -5.5 * $jmt-spacing;
        left: 50%;
        transform: translateX(-50%);
        --color: white;
    }

    &__talentid-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__select-layers-btn {
        position: absolute;
        right: 0;
        top: $jmt-spacing * 4;
        backface-visibility: hidden;
    }

    &__play-animation-btn {
        position: absolute;
        left: 0;
        top: $jmt-spacing * 4;
        backface-visibility: hidden;
    }
}
