@import 'src/theme/constants';

.help-card-small {
    width: 100%;
    max-width: $jmt-max-card-width;
    position: relative;
    padding-bottom: $jmt-spacing*4;
    cursor: pointer;

    --avatar-background: transparent;

    .custom-avatar--playlist {
        --avatar-background: white;
        .custom-avatar__position-helper {
            background-color: unset;
            background-image: unset;
        }
    }

    .custom-avatar__position-helper {
        filter: grayscale(.3);

        ion-img {
            object-fit: contain;
        }


    }
}
