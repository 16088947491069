@import "src/theme/constants";

.form-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $jmt-max-content-width;
    margin: $jmt-spacing 0;

    ion-item {
        flex-grow: 1;
        margin: 0 $jmt-spacing * -1;
        --ion-item-background: transparent;
    }

    &__item {
        display: flex;

        ion-button {
            margin-inline: 0;
            max-width: 100%;
        }

        &__label {
            width: 100%;
            height: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;

            ion-icon {
                margin-inline-end: $jmt-spacing;
                font-size: 1.4em;
            }
        }

        ion-avatar {
            border-width: 1px;
            img {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &__error-text {
        padding-left: $jmt-spacing;
    }

    input[type="file"] {
        display: none;
    }
}

.avatar-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &--for-talent:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient(circle at 49% 48%, transparent 23%, #00000018 23%);
        pointer-events: none;
    }
}
