@import 'src/theme/constants';

.delete-activity {
    display: flex;
    align-items: center;
    padding-right: $jmt-spacing;
    justify-content: space-between;

    &__avatar {
        margin-right: $jmt-spacing;
    }

    &__text {
        flex-grow: 1;
        padding: $jmt-spacing 2 * $jmt-spacing $jmt-spacing $jmt-spacing;

        &--small {
            font-size: 12px;
        }
    }
}
