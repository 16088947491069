@import 'src/theme/constants';
@import 'src/theme/mixins';

.user-card-content {
    &__background {

        &--column {
            @include background-gradient(white, $jmt-color-stripe-light, 0%);
        }
    }

    &__star-icon {
        &--active {
            color: $jmt-color-gold;
        }
    }

    &__attributes {
        overflow-x: visible!important;
    }
}

