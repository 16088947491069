@import 'src/theme/constants';

.password-settings {
    &__form {
        width: 100%;
    }

    &__input-wrapper {
        margin-bottom: 2 * $jmt-spacing;
    }
}
