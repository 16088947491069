ion-toolbar {
    display: flex;
    align-items: center;
    backdrop-filter: blur(8px);
    --min-height: #{$jmt-spacing * 8};
    --background: rgba(var(--ion-background-color-rgb), 0.95);
    --padding-start: #{$jmt-content-inset};
    --padding-end: #{$jmt-content-inset};

    & .sc-ion-searchbar-ios-h {
        padding: 0!important;
    }

    ::slotted(.buttons-last-slot) {
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
}
