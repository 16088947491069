.menu-content {
    &__content {
        --background: var(--ion-background-color);
        background: var(--ion-background-color);
    }

    &__list {
        background: transparent;
    }
}
