@import 'src/theme/constants';
@import 'src/theme/_mixins';

.item-list {
    width: 100%;

    &__container {
        @include desktop {
            display: flex;
            justify-content: space-between;
            margin: -1 * $jmt-spacing;
            flex-wrap: wrap;

            > * {
                flex-shrink: 0 !important;
                margin: $jmt-spacing !important;

                &:not(ion-infinite-scroll) {
                    width: calc(50% - #{2 * $jmt-spacing});
                }
            }
        }
    }
}
