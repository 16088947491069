@import 'src/theme/constants';

.talentcard-paper {
    position: relative;
    display: inline-flex;

    &--is-background {
        .talentcard-paper {
            &__overlay {
                left: calc(#{$jmt-spacing * 3});
                right: calc(#{$jmt-spacing * 3});
            }
        }
    }

    &__overlay {
        position: absolute;
        top: calc(#{$jmt-spacing * 11});
        left: calc(#{$jmt-spacing * 4});
        right: calc(#{$jmt-spacing * 4});
        bottom: calc(#{$jmt-spacing * 9.5});
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        z-index: 1;
    }

    &__logo {
        backface-visibility: hidden;
        position: absolute;
        left: 50%;
        top: 6%;
        transform: translateX(-50%);
        width: 30% !important;

        &__hsv {
            backface-visibility: hidden;
            position: absolute;
            left: 50%;
            top: 7%;
            transform: translateX(-50%);
            width: 30px;
        }
    }
}
