@import 'src/theme/constants';

.status {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $jmt-color-stripe-light;
    position: relative;
    width: 100%;
    color: var(--ion-color-step-600);
    min-height: $jmt-spacing * 10;
    padding: 2 * $jmt-spacing 4 * $jmt-spacing;

    &--transparent {
        background-color: transparent;
    }

    &__context-menu {
        z-index: 2;
        top: 0;
        right: 0;
        position: absolute;
    }

    &:before {
        content: '“';
        font-family: Rubik, sans-serif;
        color: var(--ion-color-step-600);
        font-size: $jmt-fontsize-h2;
        position: absolute;
        top: $jmt-spacing;
        left: $jmt-spacing * 2;
    }

    &:after {
        content: '”';
        font-family: Rubik, sans-serif;
        color: var(--ion-color-step-600);
        font-size: $jmt-fontsize-h2;
        position: absolute;
        bottom: -0.5 * $jmt-spacing;
        right: $jmt-spacing * 2;
    }
}
