@import 'src/theme/constants';

.tab-btn {
    display: flex;
    flex: 1 1 0px;
    height: 72px;
    width: 72px;
    position: relative;

    &__badge {
        position: absolute;
        right: $jmt-spacing * 2.5;
        top: $jmt-spacing * 1.5;
    }

    &__btn {

        color: var(--ion-color-step-400);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 72px;
        width: 72px;
        --border-radius: 50%;

        &--active {
            color: var(--ion-color-primary);
        }

        &--round {
            --border-radius: 50%;
            height: 64px !important;
            width: 64px !important;
            min-width: 64px !important;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        ion-icon {
            margin-top: 2px;
            margin-bottom: 2px;
            font-size: 30px;
        }

        ion-label {
            letter-spacing: 0px;
            margin-top: 2px;
            margin-bottom: 1px;
            font-size: 9px;
            min-height: 11px;
            font-weight: 500;
        }
    }
}
