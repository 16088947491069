@import "src/theme/constants";
@import 'src/theme/_mixins';

.comparison {
    overflow: visible;
    max-width: $jmt-max-card-width!important;

    &__swap-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__talent-id {

        &-wrapper {
            border-bottom-left-radius: $jmt-border-radius;
            border-bottom-right-radius: $jmt-border-radius;
            padding: $jmt-spacing * 2;
            background-color: $jmt-color-stripe-light;
        }

        &-card {
            @include background-gradient(white, $jmt-color-stripe-rgba, 40%);
            padding: $jmt-spacing * 2;
        }
    }

    &__head {
        padding: $jmt-spacing * 2;
    }

    &__talent-row {
        transform: translateX(-3 * $jmt-spacing);
        width: calc(100% + #{$jmt-spacing} * 6);
        position: relative;
    }

}
