@import "src/theme/constants";

.explain-overlay__hole__popover {
    ion-card {
        padding: $jmt-spacing;
        text-align: center;
        ion-card-title {
            justify-content: center;
            text-align: center;
        }
    }
}
