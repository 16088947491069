@import "src/theme/constants";

.relationship-icon {
    z-index: 1;
    font-size: $jmt-fontsize-h3;
    display: inline-block;

    &--colored {
        color: var(--ion-color-secondary);
    }
}
