@import "src/theme/constants";
@import "src/theme/transitions";

.form-slider {
    display: flex;
    flex-direction: column;

    &__wrapper {
        margin-bottom: $jmt-spacing;

        ion-label {
            display: inline-flex;
            align-items: center;

            ion-icon {
                padding-left: calc(#{$jmt-spacing} / 2);
            }
        }
    }

    .collapsible {
        margin-top: calc(-2 * #{$jmt-spacing});
    }

    &__range-wrapper {
        position: relative;
        margin: 0 calc(#{$jmt-spacing} * 2);
    }

    ion-range {
        padding-inline: 0;
    }

    &__reference-value {
        background-color: white;
        content: '';
        width: 3px;
        position: absolute;
        top: 50%;
        height: calc(#{$jmt-spacing} * 2.5);
        border-radius: $jmt-border-radius;
        transform: translate(-50%, -50%);
        @include standardTransition(left);
    }
}
