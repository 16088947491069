ion-skeleton-text {
    --border-radius: #{$jmt-border-radius};
}

ion-title {
    &.ios {
        ion-skeleton-text {
            margin: 0 auto;
        }
    }
    ion-skeleton-text {
        height: 14px;
    }
}

ion-card {
    &.ion-color-primary {
        ion-skeleton-text {
            --background-rgb: var(--ion-text-color-secondary-rgb);
        }
    }
}

ion-card-title {
    ion-skeleton-text {
        margin: 4px 4px 8px;
        height: 14px;
    }
}

ion-card-subtitle {
    ion-skeleton-text {
        margin: 3px;
        height: 12px;
    }
}

.custom-avatar__position-helper {
    ion-skeleton-text {
        margin: 0;
    }
}
