@import "src/theme/constants";

.buttons {
    margin: $jmt-spacing * 2.5 auto 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-word;

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-width: 240px;
        max-width: 320px;
        width: 90%;
        margin: 0 auto;

        > ion-button {
            width: 100%;

            &:nth-of-type(1) {
                margin-bottom: $jmt-spacing * 3;
            }

            &:nth-of-type(2) {
                position: relative;

                &:before {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.3);
                    content: '';
                    width: $jmt-spacing * 5;
                    height: 2px;
                    left: 50%;
                    top: -2 * $jmt-spacing + 2;
                    transform: translate(-50%, -50%);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        font-size: $jmt-fontsize-buttons-title;
        line-height: $jmt-lineheight-buttons-title;
        margin-bottom: $jmt-spacing * 2!important;
    }

    &__subtitle {
        margin-bottom: $jmt-spacing * 2;
    }

}
