@import 'src/theme/constants';

.talentcard-medium-preview-overlay {
    position: absolute;
    //top: calc(#{$jmt-spacing * 25});
    left: calc(#{$jmt-spacing * 5});
    right: calc(#{$jmt-spacing * 5});
    bottom: calc(#{$jmt-spacing * 9.5});
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    z-index: 1;
    font-size: $jmt-fontsize-s;

    > * {
        margin-bottom: $jmt-spacing * 2;

    }
    ion-button {
        margin-bottom: $jmt-spacing * 5;
    }
}
