@import "src/theme/constants";

.profile {
    &__card-wrapper {
        margin: 0 auto;
    }

    > ion-card {
        width: 100%;
    }

    &__create-btn {
        margin: 0 auto;
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
    }
}
