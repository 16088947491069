@import "/src/theme/constants";
@import "/src/theme/mixins";

.whats-new {

    &__card {
        position: relative;
        background-color: white;

        h2 {
            color: var(--ion-text-color-secondary);
        }
    }

    &__icon {
        opacity: .06;
        padding: 16px;
        position: absolute;
        top: $jmt-spacing;
        bottom: $jmt-spacing;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}
