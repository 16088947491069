@import 'src/theme/constants';

.network-page {
    .swiper-slide {
        width: 100%;
    }

    .network-detail {
        max-width: 100%;
        width: 400px;
        margin: 0 auto;
    }
}
