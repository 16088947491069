@import 'constants';
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

    --ion-font-family: Rubik;

    body {
        --ion-margin: #{$jmt-spacing};

        --ion-color-primary: #ffffff;
        --ion-color-primary-rgb: 255, 255, 255;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #efefef;
        --ion-color-primary-tint: #ffffff;

        --ion-color-secondary: #eb467a;
        --ion-color-secondary-rgb: 235,70,122;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255,255,255;
        --ion-color-secondary-shade: #cf3e6b;
        --ion-color-secondary-tint: #ed5987;

        --ion-color-tertiary: #57b8b0;
        --ion-color-tertiary-rgb: 87, 184, 176;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #46a79f;
        --ion-color-tertiary-tint: #68c9c1;

        --ion-color-success: #146531;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;

        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;

        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;

        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;

        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;

        --ion-color-light: #222428;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
    }

    --ion-background-color: #1e2228;
    --ion-background-color-rgb: 30, 34, 40;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    // custom css4 var
    --ion-text-color-secondary: #1e2228;
    --ion-text-color-secondary-rgb: 30, 34, 40;
    //

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-175: #292929;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: var(--ion-background-color);

    --ion-card-background: #343740;
    --ion-card-background-rgb: 52, 55, 64;

    ion-item {
        --padding-start: #{$jmt-spacing};
        --inner-padding-end: #{$jmt-spacing};
        --background: transparent;
    }

    ion-item-divider {
        --background: #{var(--ion-color-background)};
    }

    ion-card ion-item {
        --ion-item-background: var(--ion-card-background);
    }

    // JMT Vars
    --jmt-slides-space-between: #{$jmt-spacing-as-number * 2};
    --jmt-max-content-width: #{$jmt-max-content-width};
}
