@import 'src/theme/constants';
.onboarding {
  form {
    width: 100%;
    padding-top: $jmt_spacing * 2;
    text-align: left;
  }

  .swiper-slide {
    align-items: flex-start;
  }

  &__slides {
    height: calc(100% - env(safe-area-inset-bottom));

    ion-fab ion-button {
      margin-right: $jmt-spacing;
    }

  }

  &__button-bar {
    display: flex;
    justify-content: flex-end;
  }
}
