@import 'constants.scss';

ion-card {
    margin: 0;
    border-radius: $jmt-border-radius!important;

    ion-card-title {
        text-align: left;
        margin-bottom: $jmt-spacing * 0.5;
        @extend .font-m-semibold;
    }

    ion-card-subtitle {
        text-transform: none;
        text-align: left;
        margin-bottom: 0;
        @extend .font-s-medium;
    }

    ion-card-content {
        padding: $jmt-card-content-padding!important;
    }
}
