@import 'src/theme/_mixins';

.role-model {
    @include desktop {
        justify-content: space-between;
    }

    &__btn-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__headline {
        margin-bottom: $jmt-spacing * 2;
    }
}
