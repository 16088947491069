@import 'src/theme/constants';

.rbl-talentcard-paper-svg {
    shape-rendering: geometricPrecision;
    text-rendering: geometricPrecision;
    image-rendering:optimizeQuality;
    fill-rule: evenodd;
    clip-rule: evenodd;

    &__stop1 {
        stop-opacity: 0.619608;
        stop-color: var(--ion-color-stop1);
    }

    &__stop2 {
        stop-opacity: 1;
        stop-color: var(--ion-color-stop2);
    }

    &__path1 {
        fill: var(--ion-color-stop2, black);
    }

    &__path2 {

    }

    &__path3 {
        fill-rule: nonzero;
    }


    &--loading {
        .talentcard-paper-svg {
            &__stop1 {
                stop-color: var(--ion-color-medium);
            }

            &__path3 {
                fill: var(--ion-color-medium);
            }
        }
    }

    &--talent {
        .talentcard-paper-svg {
            &__stop1 {
                stop-color: var(--ion-color-stop1);
            }

            &__stop2 {
                stop-color: var(--ion-color-stop2);
            }

            &__path3 {
                fill: $jmt-color-stripe-light;
            }
        }
    }

    &--scout {
        .talentcard-paper-svg {
            &__stop1 {
                stop-color: var(--ion-color-scout);
            }

            &__path3 {
                fill: $jmt-color-stripe-light;
            }
        }
    }

    &--roleModel {
        .talentcard-paper-svg {
            &__stop1 {
                stop-color: var(--ion-color-roleModel);
            }

            &__path3 {
                fill: var(--ion-color-roleModel);
            }
        }
    }
}
