@import 'src/theme/constants';
@import 'src/theme/mixins';

.chose-rolemodel-form {
    position: relative;

    &__card .talentcard-medium {
        padding: 0 $jmt-spacing;
    }

    &__onboarding {
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-dark, 30%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: $jmt-spacing * 4;

        &__position-helper {
            position: relative;
        }

        &__rolemodel-card-wrapper {
            display: flex;
            flex-wrap: nowrap;
            transform-origin: 50% 50%;
            transform: scale(0.7);
            margin-top: -$jmt-spacing * 4;
            margin-bottom: -$jmt-spacing * 4;

            > .talentcard-small {
                margin-left: $jmt-spacing;
                margin-right: $jmt-spacing;
            }
        }

        .talent-rolemodel-card {
            flex-shrink: 0;
            width: 300px;
            filter: grayscale(0.9);
            position: relative;
            pointer-events: none;
            z-index: 3;

            &:nth-child(2) {
                z-index: 2;
                margin-left: -240px;
            }

            &:nth-child(3) {
                z-index: 1;
                margin-left: -240px;
            }


        }

        &__btn {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
        }
    }

    &__btn {
        margin-top: $jmt-spacing;
        margin-bottom: 4 * $jmt-spacing;
    }
}
