.swiper {
    width: 100%;
    --swiper-pagination-color: var(--ion-color-secondary);

    .swiper-pagination-progressbar {
        background-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);

    }

    .swiper-pagination-bullet {
        --bullet-background: var(--ion-color-primary);
        --bullet-background-active: var(--ion-color-secondary);
    }

    .swiper.swiper-slide {
        align-items: stretch;
    }
}
