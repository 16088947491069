@import "src/theme/constants";

.divider {
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.1) 25%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0) 100%);
    margin: 30px 0;

    &__dark {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 25%, rgba(0,0,0,.1) 75%, rgba(0,0,0,0) 100%);
    }
}
