@import 'src/theme/constants';
@import 'src/theme/mixins';

.talent-top-skills {
    padding-right: $jmt-spacing * 2;
    width: 100%;
    display: flex;
    flex-direction: row;

    &__title {
        margin-bottom: $jmt-spacing * 2;
    }

    &--compare {
        .talent-top-skills__row {
            width: 50%;
            padding-right: $jmt-spacing;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__skill-wrapper {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: $jmt-spacing * 0.5;
        }

        &--new {
            color: var(--ion-color-success-tint);
        }

        &--old {
            color: var(--ion-color-danger-tint);
        }
    }

    &__img {
        //opacity: 0.6;
        width: $jmt-spacing * 2 !important;
        height: $jmt-spacing * 2 !important;
        margin-right: $jmt-spacing;
    }

    &__skill {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ion-card {
        padding: $jmt-spacing * 2 $jmt-spacing * 4;
        width: 100%;
        @include background-gradient(transparent, $jmt-color-stripe-dark, 62%);
        margin-bottom: $jmt-spacing;

        .talent-top-skills {
            &__img {
                width: $jmt-spacing * 5 !important;
                height: $jmt-spacing * 5 !important;
                margin-right: $jmt-spacing * 3;
            }

        }

        h4 {
            color: white!important;
            font-size: $jmt-fontsize-h3;
            line-height: $jmt-lineheight-h3;
        }
    }
}
