@import 'src/theme/variables';

.talent-vote-button {
    &__modal {
        --backdrop-opacity: 0.9;
    }

    &__selection {
        margin-bottom: 8*$jmt-spacing;
    }
}
