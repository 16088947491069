.date-picker-button {

  display: flex;
  flex-direction: column;

  ion-item {
    --padding-start: 0 !important;
  }

  ion-label {
    &.md {
      transform: translateY(50%) scale(.75);
    }
    &.ios {
      transform: translate3d(0, 0, 0) scale(.82);
    }
  }

  &__picker {
    max-width: 400px !important;
  }
}