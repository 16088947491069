@import 'src/theme/constants';
.profile-edit {

  &__slides {
    height: calc(100% - env(safe-area-inset-bottom));

    &.swiper {
      margin: 0 !important;
    }

    form {
      width: 100%;
      padding-top: $jmt_spacing * 2;
      text-align: left;
    }
  }

  &__button-bar {
    margin-top: $jmt-spacing * 2;
    display: flex;
    justify-content: flex-end;

    & > *:first-child:not(:last-child) {
      margin-right: $jmt-spacing;
    }
  }
}
