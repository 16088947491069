@import 'src/theme/constants';
@import 'src/theme/_mixins';

.vote-activity {
    display: flex;
    align-items: center;
    padding-right: $jmt-spacing;
    justify-content: space-between;
    @include background-gradient(var(--ion-color-base), $jmt-color-stripe-light, 25%);

    &__skeleton {
        margin-right: $jmt-spacing;
        width: $jmt-spacing * 5;
        display: inline-block;
        --background-rgb: var(--ion-text-color-secondary-rgb);
    }

    &__avatar-skeleton {
        margin-right: $jmt-spacing;
        --background-rgb: var(--ion-text-color-secondary-rgb);
    }

    &__avatar {
        margin-right: $jmt-spacing;
    }

    &__text {
        flex-grow: 1;
        padding-right: 2 * $jmt-spacing;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;

        &--small {
            font-size: 12px;
        }
    }
}
