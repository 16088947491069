@import 'src/theme/constants';

.marketplace {
    // px is needed here... Ionic Stuff -__-
    --padding-top: 0px;


    &__condense-header {
        --padding-start: 0px;
        --padding-end: 0px;
    }

    &__search-btn {
        margin-top: 2*$jmt-spacing;
    }

    &__join-with-talent {
        --color: var(--ion-color-primary)
    }
}
