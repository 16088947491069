@import 'src/theme/constants';

.shared-talent-card-preview {

    &__content {
        --padding-top: #{$jmt-spacing * 9}!important;
        display: flex;
        justify-content: center;
    }

    &__talent-grid {
        position: relative;
        padding: $jmt-spacing;
    }

    &--rolemodel {
        .shared-talent-card-preview__talent {
            transform-origin: 50% 50%;
            transform: translate(40%) scale(0.9);

            .talentcard-paper-svg {
                filter: drop-shadow(2px 6px 6px rgba(var(--ion-color-secondary-rgb), 0.5));
            }
        }

        .shared-talent-card-preview__rolemodel {
            position: absolute;
            transform-origin: 50% 50%;
            transform: translate(-40%);

            .talentcard-paper-svg {
                filter: drop-shadow(-2px 6px 6px rgba(var(--ion-color-roleModel-rgb), 0.3));
            }
        }
    }


    &__rolemodel {
        position: absolute;
    }
}
