@import 'src/theme/constants';

.search-result {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__headline {
        margin-bottom: $jmt-spacing;
        --padding-start: 0 !important;
    }

    &__loading {
        display: flex;
        justify-content: center;
    }

    &__filter-button {
        --padding-start: #{$jmt-spacing * 2};
        --padding-end:  #{$jmt-spacing * 2};
    }

    &__filter {
        .popover-content {
            --max-width: 80%;
            --width: auto;

            @media (max-width: $jmt-max-content-width) {
                --width: 80%;
            }
        }
    }
}
