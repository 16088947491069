@import 'constants';
@import '_mixins';

h2 {
    text-transform: uppercase;
    margin: 0;
    font-size: $jmt-fontsize-h2;
    line-height: $jmt-lineheight-h2;
    font-weight: bold;
}

h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: $jmt-fontsize-h3;
    line-height: $jmt-lineheight-h3;
    font-weight: bold;
}

h4 {
    text-transform: uppercase;
    margin: 0;
    font-size: $jmt-fontsize-h4;
    line-height: $jmt-lineheight-h4;
    font-weight: bold;
    letter-spacing: 0.1;
}

.font-s-regular {
    font-style: normal;
    font-weight: normal;
    font-size: $jmt-fontsize-s;
    line-height: $jmt-lineheight-s;
    letter-spacing: 0;
}

.font-s-medium {
    @extend .font-s-regular;
    font-weight: 500;
}

.font-s-semibold {
    @extend .font-s-regular;
    font-weight: 600;
}

.font-xs-regular {
    font-style: normal;
    font-weight: normal;
    font-size: $jmt-fontsize-xs;
    line-height: $jmt-lineheight-xs;
    letter-spacing: 0;
}

.font-xs-medium {
    @extend .font-xs-regular;
    font-weight: 500;
}

.font-xs-semibold {
    @extend .font-xs-regular;
    font-weight: 600;
}

.font-l-regular {
    font-style: normal;
    font-weight: normal;
    font-size: $jmt-fontsize-l;
    line-height: $jmt-lineheight-l;
    letter-spacing: 0;
}

.font-l-medium {
    @extend .font-l-regular;
    font-weight: 500;
}

.font-l-semibold {
    @extend .font-l-regular;
    font-weight: 600;
}

.font-m-regular {
    font-style: normal;
    font-weight: normal;
    font-size: $jmt-fontsize-m;
    line-height: $jmt-lineheight-m;
    letter-spacing: 0;
}

.font-m-medium {
    @extend .font-m-regular;
    font-weight: 500;
}

.font-m-semibold {
    @extend .font-m-regular;
    font-weight: 600;
}
