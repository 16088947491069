@import 'src/theme/constants';
@import 'src/theme/_mixins';

.search-header {
    max-width: $jmt-width-desktop;
    margin: 0 auto;

    &__toolbar {
        @include desktop {
            --padding-start: 0!important;
            --padding-end: 0!important;
        }
    }
}

.search__suggestions .popover-content {
    transform: translateX(-50%);
}
