@import 'src/theme/constants';

.form-select-buttons {

    ion-label {
        &.md {
            transform: translateY(50%) scale(.75);
        }
        &.ios {
            transform: translate3d(0, 0, 0) scale(.82);
        }
    }

    &__button-list {
        padding: $jmt-spacing 0;
        margin: 0 $jmt-spacing * -1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: 100%;

        ion-button {
            margin-inline: $jmt-spacing;
            overflow: hidden;
            text-align: left;
            white-space: normal;
            --padding-bottom: calc(#{$jmt-spacing / 2});
            --padding-top: calc(#{$jmt-spacing / 2});
            height: auto;
        }

        ion-button[fill='solid'] {
            --border-width: 1px;
            --border-style: solid;
            --border-color: var(--ion-color-primary);

            &.md {
                --border-width: 2px;
            }
        }
    }

    ion-item {
        --ion-item-background: transparent;
    }
}
