.hsv-talentcard-medium {
    display: inline-flex;
    perspective: 1000px;
    position: relative;
    align-self: center;
    width: 300px;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    color: white !important;
    --color: white !important;
}
