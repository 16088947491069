@import 'src/theme/constants';

.slider {
    &__freemode {
        display: flex;
        overflow: auto;
        flex-wrap: nowrap;
        margin-left: -1 * $jmt-spacing;
        margin-right: -1 * $jmt-spacing;
        overflow-y: hidden;

        &__child-wrapper {
            padding-left: $jmt-spacing;
            padding-right: $jmt-spacing;
            flex-shrink: 0;
            height: 100%;

            > *, > * > * {
                height: 100%;
            }
        }
    }
}
