.foot-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 25px;
    height: 17px;

    &__left, &__right {
        opacity: .16;
        height: 13px !important;
        //color: white;
    }

    &__left {
        transform: scaleX(-1);
    }

    &--active {
        opacity: 1;
    }
}
