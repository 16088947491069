.skills-select-layers {

    &__reorder {
        ion-card:first-child {
            ion-label {
                --color: var(--ion-color-tertiary)
            }
        }
    }

}
