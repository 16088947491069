@import 'constants.scss';

ion-badge {
    width: $jmt-spacing * 2;
    height: $jmt-spacing * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    --padding-start: #{$jmt-spacing};
    --padding-end: #{$jmt-spacing};
    --padding-top: #{$jmt-spacing * 0.5};
    --padding-bottom: #{$jmt-spacing * 0.5};
    line-height: $jmt-spacing * 2;
    font-size: 12px;
    border-radius: 50%;
    --background: var(--ion-color-secondary);
    --color: var(--ion-text-color);
}
