// CONSTANTS
$jmt-desktop-menu-width: 320px;
$jmt-max-context-menu-width: 480px;
$jmt-max-content-width: 600px;
$jmt-max-tabbar-width: 600px;
$jmt-max-card-width: 600px;
$jmt-min-width-tablet: 600px;
// KEEP JS VAR IN SYNC
$jmt-width-desktop: 900px;
$jmt-teaser-card-height: 300px;

// SPACING
$jmt-spacing-as-number: 8; // used to calculate spacing for e.g. <IonSlides />, so change this too if spacing is changed
$jmt-border-radius: 8px;
$jmt-spacing: 8px;
$jmt-content-inset: $jmt-spacing * 1.5;
$jmt-card-content-padding: $jmt-spacing * 1.5;
$jmt-tabbar-height: $jmt-spacing * 10;

// FONT
$jmt-fontsize-buttons-title: 20px;
$jmt-lineheight-buttons-title: 24px;

$jmt-fontsize-xs: 8px;
$jmt-lineheight-xs: 12px;

$jmt-fontsize-s: 12px;
$jmt-lineheight-s: 16px;

$jmt-fontsize-m: 14px;
$jmt-lineheight-m: 1.2;

$jmt-fontsize-l: 16px;
$jmt-lineheight-l: 24px;

$jmt-fontsize-h2: 26px;
$jmt-lineheight-h2: 32px;

$jmt-fontsize-h3: 14px;
$jmt-lineheight-h3: 18px;

$jmt-fontsize-h4: 12px;
$jmt-lineheight-h4: 16px;

// CUSTOM COLORS
$jmt-color-orange: #F69C00;
$jmt-color-gold: #f09e3b;
$jmt-color-light-gold: #fceedd;

$jmt-color-stripe-rgba: rgba(0, 0, 0, 0.05);
$jmt-color-stripe-light: #eff0f5;
$jmt-color-stripe-light-preview: #f9faff;
$jmt-color-stripe-dark: #282c34;
$jmt-color-avatar-background: #ffffff;

// TRANSITIONS
$jmt-transitions: (
    // This is the most common easing curve.
    easeInOut: cubic-bezier(0.4, 0, 0.2, 1),
    // The sharp curve is used by objects that may return to the screen at any time.
    sharp: cubic-bezier(0.4, 0, 0.6, 1),
    // Objects enter the screen at full velocity from off-screen and
    // slowly decelerate to a resting point.
    easeOut: cubic-bezier(0.0, 0, 0.2, 1),
    // Objects leave the screen at full velocity. They do not decelerate when off-screen.
    easeIn: cubic-bezier(0.4, 0, 1, 1)
);

// TIMINGS
$jmt-timings: (
    shortest: 150ms,
    shorter: 200ms,
    short: 250ms,
    // most basic recommended timing
    standard: 300ms,
    // this is to be used in complex animations
    complex: 375ms,
    // recommended when something is entering screen
    enteringScreen: 225ms,
    // recommended when something is leaving screen
    leavingScreen: 195ms
);
