@import 'src/theme/constants';

.share-screen {
    margin: 0 $jmt-spacing * 2;
    padding-top: $jmt-spacing;
    padding-bottom: $jmt-spacing;

    &__title {
        margin-right: $jmt-spacing * 2;
    }
}
