@import 'src/theme/constants';

.icon-list-item {
    display: flex;
    align-items: center;

    &__grid {
        margin-left: $jmt-spacing * 2;
    }
}
