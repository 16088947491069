@import 'src/theme/constants';
@import 'src/theme/transitions';

.register {

    .swiper-slide {
        height: 100%;
        flex-wrap: wrap;
    }

    ion-card-content {
        width: 100%;
        display: flex;
        height: 100%;

        form {
            width: 100%;
        }
    }

    &__card-button-bar {
        display: flex;
        justify-content: flex-end;
    }

    &__summary {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            justify-content: center;
        }

        &__mail-icon {
            transform: scale(0);
            margin-top: $jmt-spacing * 4;
            font-size: 150px;
            @include complexTransition(transform);
            transition-delay: .5s;
            justify-self: center;

            &--visible {
                transform: scale(1);
            }
        }
    }
}
