@import "src/theme/constants";

.desktop-menu {
    max-width: $jmt-width-desktop;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;

    .jmt-button {
        --overflow: visible;

        &__badge {
            position: absolute;
            right: -20px;
            top: 0;
        }
    }
}
