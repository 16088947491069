@import 'src/theme/constants';
@import 'src/theme/mixins';

.playertype-teaser-card {
    position: relative;
    min-height: 240px;
    display: flex;

    &__img {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        max-width: none;
    }


}
