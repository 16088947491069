@import 'transitions';

:root {
    ion-tab-bar {
        height: $jmt-tabbar-height;
        --ion-tab-bar-background: var(--ion-card-background);
        border-radius: $jmt-spacing * 3 $jmt-spacing * 3 0 0;
        margin-top: $jmt-spacing * -1;

        ion-tab-button {
            --background: transparent;

            &.center-highlight {
                min-width: $jmt-spacing * 8;
                min-height: $jmt-spacing * 8;
                max-height: $jmt-spacing * 8;
                max-width: $jmt-spacing * 8;
                border-radius: 100px;
                margin: 0 $jmt-spacing;
            }

            &.secondary {
                --color: var(--ion-text-color);
                --background: var(--ion-color-secondary);
            }
        }

        &.hidden {
            display: none;
        }
    }
}
