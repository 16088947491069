@import 'src/theme/constants';

.talent-attribute {
    padding: $jmt-spacing;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ion-row {
        min-height: 16px;
    }

    &__main {
        margin: $jmt-spacing * 0.5 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
