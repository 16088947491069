@import 'src/theme/variables';

.talent-short-vote-form {

    &__slider-wrapper {
        ion-range.range-has-pin  {
            padding-top: $jmt-spacing;
            padding-bottom: $jmt-spacing*1.5;
        }
    }
}
