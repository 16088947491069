@import 'src/theme/constants';
@import 'src/theme/mixins';

.rolemodel-teaser-card {
    @include background-gradient(white, $jmt-color-stripe-light, 40%);
    position: relative;
    display: flex;
    height: $jmt-teaser-card-height;

    &__video {
        position: absolute;
        right: $jmt-spacing * -10;
        bottom: -1px;
    }
}
