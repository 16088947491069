@import 'src/theme/constants';

.settings {
    ion-accordion-group {
        width: 100%;
    }

    ion-accordion {
        width: 100%;
        margin-bottom: $jmt-spacing;
    }
}
