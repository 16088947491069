@import 'src/theme/constants';
@import 'src/theme/mixins';

.performance-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    contain: strict;
    box-sizing: content-box !important;
    fill: #77e854;
    cursor: pointer;
    transform-style: preserve-3d;

    .steady {
        display: none;
    }

    &--negative {
        fill: red !important;
        transform: rotate(180deg);
    }

    &--one {
       .first, .second {
           fill: var(--ion-color-step-850) !important;
           opacity: .6;
        }
    }

    &--two {
        .first {
            fill: var(--ion-color-step-850) !important;
            opacity: .6;
        }
    }

    &--none {

        .steady {
            display: unset !important;
        }
        .first, .second, .third {
            fill: var(--ion-color-step-850) !important;
            opacity: .6;
        }

        &:not(.performance-icon--hide) {
            .first, .second, .third {
                display: none;
            }
        }
    }

    &--hide {
        .steady {
            display: none !important;
        }
    }
}

.talent-performance {

    &__modal-content {
        .swiper-slide {
            flex-direction: column;
            text-align: center;
        }

        ion-item {
            max-width: 100%;
            align-self: center;
        }
    }

    &__card-wrapper {
        display: flex;
        justify-content: center;
        padding: $jmt-spacing 0;
        width: 100%;
    }

    &__card {
        text-align: left;
        display: flex;
        flex-grow: 1;
        max-width: 350px !important;
        padding: $jmt-spacing * 2;
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-rgba, 20%);
        font-size: $jmt-fontsize-l;
        line-height: $jmt-lineheight-l;

        &--disabled {
            color: var(--ion-color-step-650) !important;
        }

        &--private {
            .talent-performance__card__icon {
                position: relative;
                width: 22px;
                height: 22px;

                &.performance-icon--hide {
                    display: none;
                }

                &.performance-icon--one {

                    &.performance-icon--negative {
                        .second {
                            fill:red !important;
                        }
                    }

                    .first,
                    .third {
                        display: none !important;
                    }

                    .second {
                        display: unset !important;
                        fill: green !important;
                    }
                }
            }
        }

        &__icon {
            display: inline-flex;
            width: 15px;
            height: 15px;
            position: relative;
            fill: green;

            .steady {
                display: none;
            }

            .performance-icon--none {
                .steady {
                    display: none !important;
                }
            }

            .first, .second, .third {
                opacity: 1 !important;
            }
        }

        ion-label {
            display: inline-flex;
            align-items: center;
        }

        ion-icon {
            margin: 0 $jmt-spacing 0 $jmt-spacing*0.5;
        }
    }
}
