:root {
    --ion-color-scout: #0457aa;
    --ion-color-scout-rgb: 4,87,170;
    --ion-color-scout-contrast: #ffffff;
    --ion-color-scout-contrast-rgb: 255,255,255;
    --ion-color-scout-shade: #034385;
    --ion-color-scout-tint: #0b6fd5;
}

 :root {
     --ion-color-roleModel: #f0f0ab;
     --ion-color-roleModel-rgb: 240,240,171;
     --ion-color-roleModel-contrast: #000000;
     --ion-color-roleModel-contrast-rgb: 0,0,0;
     --ion-color-roleModel-shade: #d3d396;
     --ion-color-roleModel-tint: #f2f2b3;
 }

 .ion-color-roleModel {
     --ion-color-base: var(--ion-color-roleModel);
     --ion-color-base-rgb: var(--ion-color-roleModel-rgb);
     --ion-color-contrast: var(--ion-color-roleModel-contrast);
     --ion-color-contrast-rgb: var(--ion-color-roleModel-contrast-rgb);
     --ion-color-shade: var(--ion-color-roleModel-shade);
     --ion-color-tint: var(--ion-color-roleModel-tint);
 }

.ion-color-scout {
    --ion-color-base: var(--ion-color-scout);
    --ion-color-base-rgb: var(--ion-color-scout-rgb);
    --ion-color-contrast: var(--ion-color-scout-contrast);
    --ion-color-contrast-rgb: var(--ion-color-scout-contrast-rgb);
    --ion-color-shade: var(--ion-color-scout-shade);
    --ion-color-tint: var(--ion-color-scout-tint);
}
