@import 'src/theme/constants';

.hsv-certificate-wrapper {

  ion-grid {
    padding: $jmt-spacing;
  }

  padding: $jmt-spacing;
  //--accent-color: rgb(0 90 170);
  --accent-color: rgb(0 50 100);
  background-color: var(--accent-color);
  @media(min-width: 993px) {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.5);
    height: 640px;
  }
  position: relative;
  margin-bottom: $jmt-spacing * 2;

  @media (max-width: 992px) {
    width: 360px;
    margin: 0 auto;
  }

  --ion-font-family: HsvSans;

  /** primary **/
  --ion-color-primary: #005aaa;
  --ion-color-primary-rgb: 0, 90, 170;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #034385;
  --ion-color-primary-tint: #0b6fd5;

  /** secondary **/
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0f0f0f;
  --ion-color-secondary-tint: #373737;

  @font-face {
    font-family: HsvSans;
    src: url('./../../../assets/fonts/HsvSans-Regular.ttf');
    font-weight: normal;
  }

  @font-face {
    font-family: HsvSans;
    src: url("./../../../assets/fonts/HsvSans-Italic.ttf");
    font-style: italic;
  }

  @font-face {
    font-family: HsvSans;
    src: url("./../../../assets/fonts/HsvSans-Bold.ttf");
    font-weight: 500;
  }

  @font-face {
    font-family: HsvSans;
    src: url("./../../../assets/fonts/HsvSans-BoldItalic.ttf");
    font-style: italic;
    font-weight: 500;
  }

  @font-face {
    font-family: HsvSlab;
    src: url('./../../../assets/fonts/HsvSlab-Bold.ttf');
    font-weight: normal;
  }

  @font-face {
    font-family: HsvSlab;
    src: url("./../../../assets/fonts/HsvSlab-Italic.ttf");
    font-style: italic;
  }

  //@font-face {
  //    font-family: HsvSlab;
  //    src: url("./../../../assets/fonts/HsvSlab-Bold.ttf");
  //    font-weight: 700;
  //}

  @font-face {
    font-family: HsvSlab;
    src: url("./../../../assets/fonts/HsvSlab-BoldItalic.ttf");
    font-style: italic;
    font-weight: 500;
  }

  .certificate {
    height: 100%;
    color: white;
    background-color: var(--accent-color);
    overflow: hidden;
    @media (min-width: 993px) {
      //border: 2px solid white;
      padding: $jmt-spacing $jmt-spacing $jmt-spacing;
      height: 624px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    * {
      font-family: HSV, sans-serif;
    }

    &__main {
      &__talent-card-wrapper {
        @media (min-width: 993px) {
          max-width: 33%;
          padding: 24px 16px 16px 6px;
          .hsv-talentcard-medium {
            scale: .9;
          }
        }
        @media (max-width: 992px) {
          display: flex;
          justify-content: center;
        }
      }

      &__content {
        &__header {
          &__title {
            &__headline {

              @media (max-width: 992px) {
                display: none;
              }

              margin-top: 16px;
              height: 90px;
              flex-direction: column;
              padding-right: 16px;

              h3 {
                font-size: 24px;
              }

              h1 {
                margin-top: 0;
                font-size: 42px;
              }

              &--mobile {
                @media (min-width: 993px) {
                  display: none;
                }

                display: flex;

                text-align: left;

                h3 {
                  margin-top: 32px;
                  min-width: 300px;
                  font-size: 20px;
                }

                h1 {
                  margin-bottom: 32px;
                  font-size: 29px;
                  min-width: 300px;
                }
              }
            }

            &__name {
              @media (min-width: 993px) {
                height: 118px;
                align-content: flex-end;
              }
            }

            .name {
              font-weight: bolder;
              font-size: 36px;
              margin-top: 48px;
              @media (max-width: 992px) {
                min-width: 300px;
                margin-top: 0;
              }
            }
          }

          &__name {
            height: 87px;
            vertical-align: bottom;
          }

          &__img-wrapper {
            max-width: 100px;

            @media (max-width: 992px) {
              margin-top: 48px;
              flex-direction: row !important;
              justify-content: center;
              max-width: 100%;
              img {
                width: 100px;
              }

              > canvas {
                margin-left: 32px;
                margin-top: 0 !important;
                max-width: 110px !important;
                max-height: 110px !important;
                margin-right: 16px;
              }
            }

            > *:not(:last-child) {
              margin-bottom: $jmt-spacing;
            }

            > canvas {
              margin-top: 16px;
              max-width: 90px;
              max-height: 90px;
            }
          }
        }

        &__text {

        }

        &__signature {
          margin-top: 48px;
          font-size: 20px;

          &__name {
            font-weight: bold;
          }
        }

      }

      &__signature {
        align-items: center;
        margin-top: 32px;

        &__sign {
          width: 130px;
          height: 80px;
        }

        &__siegel {
          max-width: 80px;
          height: auto;
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 20px;
      padding-right: 16px;
      text-align: justify;
      padding-left: 8px;
      line-height: 1;

      @media (max-width: 992px) {
        position: relative;
        margin-top: 48px;
      }

      ion-text {
        font-size: 10px;
        text-align: justify;
      }

      &__img {
        width: auto;
        height: 45px;
        border: 2px solid white;
        margin-top: 8px;
      }

      &__logo {
        height: 45px;
        margin-top: 9px;
      }
    }

    &__page-2 {
      align-items: start;
    }

    &__id {
      max-width: 200px;

      .talentcard-medium {
        scale: .6;
        left: -56px;
      }
    }

    &__texts {
      //margin: 16px;
      h1 {
        font-weight: bold;
        margin: 8px 16px 16px 12px;
        @media(min-width: 993px) {
          margin: 16px 4px 16px 4px;
        }
      }

      @media(min-width: 993px) {
        padding-left: 8px;
        padding-right: 24px;
      }

      &__block {
        @media(max-width: 992px) {
          min-width: 300px;
          margin-right: 0;
        }

        text-align: justify;
        margin-bottom: $jmt-spacing*2;

        h5 {
          font-weight: bolder;
          margin-left: 8px;
          margin-right: 8px;
          @media(min-width: 993px) {
            margin-left: 16px;
          }
        }

        &__description {
          ion-text {
            font-size: 12px !important;
          }

          line-height: .8;
          margin-left: 8px;
          margin-right: 8px;
          @media(min-width: 993px) {
            margin-left: 16px;
          }
        }
      }
    }

    .hide-small {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .hide-big {
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
}
