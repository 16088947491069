@import "src/theme/variables";
@import "src/theme/mixins";

.onboarding-modal {
    --ion-background-color: rgba(var(--ion-color-scout-rgb), 0.8);
    ion-toolbar {
        --background: rgba(var(--ion-color-scout-rgb), 0.8);
    }
    backdrop-filter: blur(6px);

    .swiper-slide {
        display: flex;
        flex-direction: column;
        width:  100vw;
        white-space: pre-line;
        text-align: left;

        > * {
            width: 100%;
        }
    }

    &__footer {
        width: 100%;
        max-width: $jmt-width-desktop;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        padding: $jmt-spacing * 2;

        &__buttons ion-button {
            margin-left: $jmt-spacing * 2;
        }
    }
}

.onboarding-fab {
    animation-name: animate-pop;
    animation-duration: map-get($jmt-timings, complex);
    animation-delay: .75s;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-timing-function: map-get($jmt-transitions, easeOut);
    position: fixed;
    top: 79vh;

    @media (min-width: $jmt-width-desktop) {
        animation-name: animate-pop-desktop;
        top: 80px;
        right: calc(50% - #{$jmt-width-desktop*0.5} + #{$jmt-spacing*2});
    }
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        top: 78vh;
    }
    100% {
        opacity: 1;
        top: 79vh;
    }
}

@keyframes animate-pop-desktop {
    0% {
        opacity: 0;
        top: 60px;
    }
    100% {
        opacity: 1;
        top: 80px;
    }
}
