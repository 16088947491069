@import 'src/theme/constants';
@import 'src/theme/_mixins';

.content {
    --padding-top: #{$jmt-spacing};

    &--with-tab-bar {
        --padding-bottom: calc(72px + env(safe-area-inset-bottom)) !important;
    }
    ion-refresher {
        z-index: 10;
        --ion-text-color: var(--ion-color-secondary);
        color: var(--ion-color-secondary) !important;
        max-width: 100% !important;
    }

    ion-spinner {
        --color: var(--ion-color-secondary);
        color: var(--ion-color-secondary) !important;
    }
}
