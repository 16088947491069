@import 'src/theme/constants';
@import 'src/theme/_mixins';

.header {
    max-width: $jmt-width-desktop;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    &__toolbar {
        @include desktop {
            --padding-start: 0!important;
            --padding-end: 0!important;
        }

        &--dev {
            border-bottom: 3px solid var(--ion-color-warning)
        }
    }


    &__skeleton {
        width: $jmt-spacing * 8;
    }

    &__btn {
        // reduce flicker
        min-width: $jmt-spacing * 6;
        min-height: $jmt-spacing * 6;
        margin-inline-end: 0;

        > * {
            min-width: $jmt-spacing * 6;
            min-height: $jmt-spacing * 6;
            margin: 0!important;
        }

        &--hide-desktop {
            @include desktop {
                display: none;
            }
        }
    }

    .jmt-button--round {
        --border-radius: 50%!important;
        width: 45px;
        height: 45px;
    }

    .ribbon {
        margin: 0;
        background: var(--ion-color-secondary);
        color:white;
        padding:1em 0;
        position: absolute;
        top:0;
        right:0;
        transform: translateX(-100%) translateY(0%) rotate(45deg);
        transform-origin: top left;
        z-index: 11;
    }
    .ribbon:before,
    .ribbon:after {
        content: '';
        position: absolute;
        top:0;
        margin: 0 -1px; /* tweak */
        width: 200%;
        height: 100%;
        background: var(--ion-color-secondary);
    }
    .ribbon:before {
        right:100%;
    }

    .ribbon:after {
        left:100%;
    }
}
