@import 'src/theme/constants';

.talentcard-small {
    width: 150px;
    position: relative;
    align-self: center;
    display: inline-flex;

    ion-skeleton-text {
        margin: 0;
    }

    .talentcard-paper__overlay {
        left: $jmt-spacing * 1.5;
        right: $jmt-spacing * 1.5;
        top: $jmt-spacing * 4;
        bottom: $jmt-spacing * 4;
    }

    &__fifa-country {
        width: 22px !important;
        border-radius: 2px;
    }

    &__performance-icon {
        width: 22px;
        height: 22px;
        text-align: center;
        margin-top: $jmt-spacing * 0.5;
    }

    &__profile-link {
        cursor: pointer;

        > * {
            font-size: 11px;
            line-height: 12px;
            text-shadow: 0 0 4px black;
        }
    }

    &--talent {
        .talentcard-small {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 3px black, 0 0 4px var(--ion-color-secondary-shade);
                }
            }

            &__attr-title {
                color: var(--ion-color-secondary-tint);
            }
        }
    }

    &--scout {
        .talentcard-small {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 3px black, 0 0 4px var(--ion-color-scout-shade);
                }
            }

            &__attr-title {
                color: var(--ion-color-scout-tint);
            }
        }
    }

    &--roleModel {
        .talentcard-small {
            &__top-container {
                h2, h3 {
                    text-shadow: 0 0 3px black, 0 0 4px var(--ion-color-roleModel-shade);
                }
            }

            &__attr-title {
                color: var(--ion-color-roleModel-tint);
            }
        }
    }

    &__top-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: $jmt-spacing * 0.25;

        h2 {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: calc(#{$jmt-spacing} * 0.25);
            width: 20px;
            display: flex;
            justify-content: center;
        }
    }

    &__bottom-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__info-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__talentid-wrapper {
        flex-shrink: 0;
        width: 75px;
        height: 75px;
        cursor: pointer;
    }

    &__ctx-menu {
        position: absolute;
        bottom: -3.5 * $jmt-spacing;
        left: 50%;
        transform: translateX(-50%);
    }

    h4 {
        font-size: 11px!important;
        line-height: 14px!important;
    }

    &__attr-wrapper {
        display: flex;
        flex-direction: column;

        &__attr:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__attr-title {
        margin-bottom: 2px;
        font-weight: normal;
    }

    &__attr-title, &__attr-text {
        text-align: left;
    }
}
