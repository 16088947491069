@import 'src/theme/constants';
@import 'src/theme/mixins';

.network-list-item {
    position: relative;
    display: flex;
    margin-bottom: $jmt-spacing * 1.5;

    &::part(native) {
        display: flex;
    }

    ion-card-content {
        width: 100%;
    }

    &__name {
        word-break: break-word;
    }

    &__owner {
        display: flex;
        align-items: center;

        ion-icon {
            margin-right: $jmt-spacing;
        }
    }

    &__grid {
        padding: 0 $jmt-spacing * 2;
    }

    ion-grid {
        display: flex;
    }

    ion-row {
        width: 100%;
    }

    &__menu {
        flex-grow: 0;
    }
}
