@import "src/theme/constants";
@import "src/theme/transitions";
@import "src/theme/typography";

.form-input {
    border-bottom: 1px solid var(--ion-color-primary);
    overflow: visible;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: $jmt-spacing;

    &::after {
        border-bottom: 1px solid var(--ion-color-secondary);
        content: '';
        display: block;
        bottom: -1px;
        margin: 0 auto;
        position: absolute;
        transform: scaleX(0);
        @include shortestTransition(transform);
        will-change: transform;
        width: 100%;
        z-index: 10;
    }

    &__wrapper {
        position: relative;
        width: 100%;
    }

    &__input {
        &__password {
            padding-right: $jmt-spacing * 7 !important;
        }
        &__length {
            position: absolute;
            opacity: .7;
            right: $jmt-spacing;
            bottom: 0;
            @extend .font-s-regular;
        }

        &--date {
            max-width: calc(100vw - (#{$jmt-spacing * 5}));
        }
    }

    &__prefix {
        opacity: 1;
        @include standardTransition(opacity);
        margin-right: $jmt-spacing;
        color: var(--ion-color-step-750);

        &--hidden {
            opacity: 0;
            @include shortestTransition(opacity);
        }
    }

    &__item:not(.item-has-focus) {
        ion-label {
            transform: translateY(32px);

            &.form-input__label--date {
                &.md {
                    transform: translateY(10%) scale(.75);
                }
                &.ios {
                    transform: translateX(-8%) scale(.82);
                }
            }
        }
    }

    &--focused::after {
        transform: scaleX(1);
        border-bottom: 1px solid var(--ion-color-secondary);
    }

    &--hidden {
        display: none;
    }

    ion-item {
        flex-grow: 1;
        margin: 0 $jmt-spacing * -1;
        overflow: visible;
        --ion-item-background: transparent;
    }

    &__eye-button {
        z-index: 11;
        position: absolute;
        right: 0;
        top: -0.5 * $jmt-spacing;
        font-size: 17px;
        margin-right: 0;
    }

    &__error-container {
        text-align: left;
    }

    &__error-text {
        padding-left: $jmt-spacing;
    }
}
