@import "src/theme/transitions";
@import 'src/theme/mixins';

.talent-vote-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: $jmt-min-width-tablet;
    margin: 0 auto!important;

    &__slider-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        .form-slider {
            flex-grow: 1;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__value {
        margin-top: $jmt-spacing * 3;
        margin-left: $jmt-spacing * 0.5;
        width: 18px; // keep width consistent - optional suffix layout shift
        white-space: nowrap;
    }

    &__date-picker-button  {
        .date-picker-button {
            width: 100%;
        }

        ion-button {
            width: 100%;
            font-size: 1rem !important;
            font-weight: 500 !important;
            --border-radius: 14px !important;
            --padding-top: 13px !important;
            --padding-bottom: 13px !important;
            --padding-start: 1em !important;
            --padding-end: 1em !important;
        }
    }

    &--active-0 {
        .talent-vote-form {
            &__sectors {
                transform: translate3d(0, 0, 0);
            }
            &__talent-id-wrapper {
                transform: translate(-50%, -50%) rotate(45deg);

            }
        }

    }

    &--active-1 {
        .talent-vote-form {
            &__sectors {
                transform: translate3d(-25%, 0, 0);
            }
            &__talent-id-wrapper {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &--active-2 {
        .talent-vote-form {
            &__sectors {
                transform: translate3d(-50%, 0, 0);
            }
            &__talent-id-wrapper {
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        }
    }

    &--active-3 {
        .talent-vote-form {
            &__sectors {
                transform: translate3d(-75%, 0, 0);
            }
            &__talent-id-wrapper {
                transform: translate(-50%, -50%) rotate(-225deg);
            }
        }
    }

    &__overflow {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    &__segment {
        overflow: auto;
        flex-shrink: 0;
        z-index: 1;
        margin-bottom: $jmt-spacing * 2;
        margin-top: 0;
        width: 100%;
    }

    &__card {
        overflow: auto;
        position: relative;
        border-radius: $jmt-border-radius;
    }

    &__sectors {
        width: 400%;
        display: flex;
        opacity: 1;
        @include complexTransition(transform, opacity);
    }

    &__sector {
        width: 25%;
        padding: $jmt-spacing * 2;
        height: 100%;
        overflow: auto;
    }

    &__talent-id-wrapper {
        transform-origin: 50% 50%;
        position: absolute;
        width: 150%;
        top: 50%;
        left: 0;
        //z-index: -1;
        pointer-events: none;
        opacity: 1;
        @include complexTransition(transform, opacity);

        svg {
            filter: grayscale(1) opacity(0.15);
        }
    }

    &__talent-id-wrapper-short {
        transform-origin: 50% 50%;
        position: absolute;
        width: 80%;
        left:50%;
        transform: translateX(-50%);
        pointer-events: none;
        opacity: 1;
        @include complexTransition(transform, opacity);

        svg {
            filter: grayscale(1) opacity(0.15);
        }
    }

    &__preview {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include complexTransition(opacity);
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-dark, 30%);
        background-color: var(--ion-background-color);
        padding: $jmt-spacing * 2;
        padding-top: $jmt-spacing * 10;
        pointer-events: none;

        * {
            pointer-events: none;
        }

        &--active {
            opacity: 1;
            pointer-events: all;

            * {
                pointer-events: all;
            }
        }

        > * {
            max-width: $jmt-min-width-tablet;
            margin: 0 auto $jmt-spacing * 2!important;
        }
    }

    &__top-skills-wrapper {
        @include background-gradient(var(--ion-color-base), $jmt-color-stripe-light, 50%);
        width: 100%;
        padding: $jmt-spacing;
        margin-bottom: $jmt-spacing * 2!important;
    }

    &__preview__talent-id-wrapper {
        max-width: 300px;
        margin: 0 auto;
    }

    &__preview-btn {
        flex-shrink: 0;
        margin-top: $jmt-spacing;
        margin-bottom: $jmt-spacing * 2;
    }

    &__submit-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        > *:not(:first-child) {
            margin-left: $jmt-spacing;
        }
    }
}
