@import 'src/theme/constants';

.skills-list-view-btn {
    &__modal-title-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    &__modal-title-img-wrapper {
        display: inline-flex;
        flex-wrap: nowrap;
    }

    &__modal-title-img {
        background-color: rgba(0, 0, 0, 0.05);
        flex-shrink: 0;
        width: $jmt-spacing * 4;
        height: $jmt-spacing * 4;
        display: block;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
        border: 2px solid white;

        &:not(:first-child) {
            margin-left: $jmt-spacing;
        }

        &--secondary {
            border-color: var(--ion-color-secondary);
        }

        &--tertiary {
            border-color: var(--ion-color-tertiary);
        }
    }
}
